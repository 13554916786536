import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Studio1images from "./Studio1";
import Studio2images from "./Studio2";
import Studio3images from "./Studio3";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
  faN,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";
import {faSwimmingPool} from "@fortawesome/free-solid-svg-icons/faSwimmingPool";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const O8wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Apartemen%20Lagoon,%20ada%20kamar%20available?%20Saya%20Tertarik%20Promo%20150k%20(Studio%201)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tanamaswa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Apartemen%20Lagoon,%20ada%20kamar%20available?%20Saya%20Tertarik%20Promo%20150k%20(Studio%202)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Zlivwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Apartemen%20Lagoon,%20ada%20kamar%20available?%20Saya%20Tertarik%20Promo%20150k%20(Studio%203)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Studio1images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Studio 1</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Open 24 Hours</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faN} />
            <span> Netflix</span>
            <FontAwesomeIcon color="#091c34" icon={faWifi} />
            <span> Wifi</span>
            <FontAwesomeIcon color="#091c34" icon={faSwimmingPool} />
            <span> Pool</span>
            <FontAwesomeIcon color="#091c34" icon={faBuilding} />
            <span> City View</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">150rb</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={O8wa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Studio2images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Studio 2</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Open 24 Hours</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faN} />
            <span> Netflix</span>
            <FontAwesomeIcon color="#091c34" icon={faWifi} />
            <span> Wifi</span>
            <FontAwesomeIcon color="#091c34" icon={faSwimmingPool} />
            <span> Pool</span>
            <FontAwesomeIcon color="#091c34" icon={faBuilding} />
            <span> City View</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">150rb</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tanamaswa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Studio3images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Studio 3</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Open 24 Hours</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faN} />
            <span> Netflix</span>
            <FontAwesomeIcon color="#091c34" icon={faWifi} />
            <span> Wifi</span>
            <FontAwesomeIcon color="#091c34" icon={faSwimmingPool} />
            <span> Pool</span>
            <FontAwesomeIcon color="#091c34" icon={faBuilding} />
            <span> City View</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">150rb</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Zlivwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
