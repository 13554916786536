import React from "react";
import "./surrounding.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Surroundingimages from "./surrounding.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div id="surroundingarea" className="surroundingarea">
      <div className="container-surrounding">
        <div id="surrounding" className="judul-surrounding">
          <h1>Fasilitas Grand Kumala Lagoon</h1>
        </div>
        <div className="container-card">
          <div className="kartu">
            <Slider {...settings}>
              {Surroundingimages.map((image, index) => (
                <div>
                  <img
                    className="kartugambar1"
                    key={index}
                    src={image}
                    alt="athena"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
